var Handlebars = require("../js/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.full_name : depth0), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.github_username : depth0), depth0))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"flex mb-2 items-center\">\n    <img class=\"rounded-full h-16 w-16\" src=\"https://avatars.githubusercontent.com/"
    + alias2(alias1((depth0 != null ? depth0.github_username : depth0), depth0))
    + "\" alt=\"Placeholder image\">\n    <div class=\"ml-2\">\n        <a class=\"text-gray-900 font-bold text-sm\" href=\"/"
    + alias2(alias1((depth0 != null ? depth0.github_username : depth0), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.full_name : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "        </a>\n        <p class=\"text-gray-700 text-xs max-w-lg\">\n            "
    + alias2(alias1((depth0 != null ? depth0.bio : depth0), depth0))
    + "\n        </p>\n    </div>\n"
    + ((stack1 = container.invokePartial(require("./follow_button.handlebars"),depth0,{"name":"follow_button","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});