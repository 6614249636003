var Handlebars = require("../js/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.full_name : stack1), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.github_username : stack1), depth0))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "<div class=\"mb-3\">\n    <div class=\"flex justify-start items-center\">\n        <img class=\"flex-initial h-4 rounded\"\n            src=\"https://avatars.githubusercontent.com/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.github_username : stack1), depth0))
    + "?s=64\">\n\n        <a class=\"flex-initial font-normal has-text-dark mx-2 text-xs text-gray-900\"\n            href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.github_username : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.profile : depth0)) != null ? stack1.full_name : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "        </a>\n    </div>\n    <h2>\n        <a href=\""
    + alias2(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"link","hash":{},"data":data}) : helper)))
    + "\" class=\"post_link text-sm font-semibold text-gray-900\" target=\"_blank\"\n            rel=\"noopener noreferrer\">\n            "
    + alias2(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"title","hash":{},"data":data}) : helper)))
    + "\n        </a>\n    </h2>\n</div>\n";
},"useData":true});